import { Alert, Button, ErrorLabel } from '@equitymultiple/react-eui';
import GoogleButton from 'components/GoogleButton/GoogleButton';
import OfferingSignUp from 'components/OfferingSignUp/OfferingSignUp';
import {
  ReduxFormCaptcha,
  ReduxFormCheckboxField,
  ReduxFormInputField,
  ReduxFormPasswordField
} from 'components/ReduxFormFields';
import logo from 'images/logos/full-logo-blue.svg?url';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { createSignin, fetchSignin } from 'redux/actions/auth';
import {
  change,
  clearSubmitErrors,
  Field,
  getFormValues,
  InjectedFormProps,
  reduxForm,
  reset,
  stopSubmit,
  touch,
  untouch
} from 'redux-form';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import utils from 'utilities/utils';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import * as styles from './SignIn.module.scss';
import signInSchema from './validation';

interface FormData {
  email: string;
  password: string;
  remember_email: boolean;
}

interface Props extends Partial<InjectedFormProps<FormData, Props>> {
  dispatch: Dispatch;
  error?: string;
  fetching: boolean;
  formValues: object;
  googleLoginUrl: string;
}

const SignIn = ({
  dispatch,
  error,
  fetching,
  formValues,
  googleLoginUrl,
  handleSubmit
}: Props) => {
  const navigate = useNavigate();
  const [otpEnabled, setOtpEnabled] = useState(false);
  const [otpResent, setOtpResent] = useState(false);
  const [resendAttempt, setResendAttempt] = useState(false);
  const [resendVerificationTimer, setResendVerificationTimer] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [initialSubmit, setInitialSubmit] = useState(true);
  const [captchaRequired, setCaptchaRequied] = useState(false);
  const [captchaSubmitted, setCaptchaSubmitted] = useState(false);
  const [initialCaptchaRequired, setInitialCaptchaRequired] = useState(false);
  const [sendCodeAfterCaptcha, setSendCodeAfterCaptcha] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const prevSendCodeAfterCaptcha = useRef(false);

  const phoneLastFour = phoneNumber
    ? phoneNumber.replace(/\D/g, '').slice(-4)
    : '';

  useEffect(() => {
    dispatch(fetchSignin());
    document.title = 'Login | EquityMultiple';
    document.body.classList.add('login-page');
    document.getElementById('email').focus();
    dispatch(reset('signin'));

    return () => {
      document.body.classList.remove('login-page');
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (resendVerificationTimer > 0) {
      setTimeout(() => {
        if (resendVerificationTimer > 0)
          setResendVerificationTimer(resendVerificationTimer - 1);
      }, 1000);
    }
  }, [resendVerificationTimer]);

  const handleCaptchaRequired = useCallback(
    result => {
      if (result.captcha_required) {
        // If the OTP was successfully sent, we should not show or require a captcha for OTP submission
        setShowCaptcha(!result.otp_sent);
        dispatch(change('signin', 'captcha_required', !result.otp_sent));
        dispatch(change('signin', 'captcha_response', ''));
        setCaptchaRequied(true);
        setInitialCaptchaRequired(initialSubmit);
      }
    },
    [dispatch, initialSubmit]
  );

  const handleResendSuccess = () => {
    setOtpResent(true);
    setResendVerificationTimer(30);
    setResendAttempt(false);
  };

  const handleSubmitError = useCallback(
    (err, data) => {
      let errors = { password: undefined, _error: undefined };

      if (err && [400, 401, 403, 429].includes(err.status_code)) {
        if (err.body.message === 'Your account is locked.') {
          navigate('/users/locked', {
            state: { email: data.email }
          });
        } else if (err.body.errors) {
          errors = err.body.errors;
        } else if (err.status_code === 401) {
          errors.password = err.body.message;
        } else {
          errors._error =
            err.body.message || 'Something went wrong. Please try again!';
        }
      } else {
        errors._error =
          err.body?.message || 'Something went wrong. Please try again!';
      }

      throwSubmissionErrors({ body: { errors } });
    },
    [navigate]
  );

  const signIn = useCallback(
    data => {
      return dispatch(createSignin({ user: data }))
        .then(result => {
          if (data.remember_email)
            utils.setLocalStorage('em_user_email', data.email);
          else utils.removeLocalStorage('em_user_email');

          if (result.otp_attempt) {
            dispatch(change('signin', 'otp_required', true));
            setOtpEnabled(true);
            setPhoneNumber(result.phone);
          } else {
            EmAnalytics.identify(result.id, { email: result.email });
            let afterLoginPath = utils.getAfterLoginPath(
              result.after_login_path
            );

            // Only users with incomplete signups will have the "step" property present
            if (result.step) {
              let signupRoute = 'name';
              if (result.step === 4) signupRoute = 'accreditation';

              afterLoginPath = `/users/signup/${signupRoute}`;
            }

            window.location.replace(afterLoginPath);
          }

          handleCaptchaRequired(result);

          if (resendAttempt && result.otp_sent) handleResendSuccess();

          if (initialSubmit) setInitialSubmit(false);
        })
        .catch(result => handleSubmitError(result, data));
    },
    [
      dispatch,
      handleCaptchaRequired,
      resendAttempt,
      initialSubmit,
      handleSubmitError
    ]
  );

  const onSubmit = data => {
    data.time_zone = -new Date().getTimezoneOffset();

    if (resendAttempt) setResendAttempt(false);

    if (data.otp_attempt) data.otp_attempt = data.otp_attempt.trim();

    return signIn(data);
  };

  const sendVerificationCode = useCallback(
    data => {
      signIn(data)
        .then(() => {
          if (initialCaptchaRequired) setInitialCaptchaRequired(false);
        })
        .catch(err => {
          if (!err.errors?.captcha_response) {
            if (initialCaptchaRequired) {
              setShowCaptcha(false);
              setInitialCaptchaRequired(false);
              setOtpResent(false);
              setOtpEnabled(false);
              setCaptchaRequied(false);
              setInitialSubmit(true);
              setResendAttempt(false);
              dispatch(reset('signin'));
            } else {
              setShowCaptcha(false);
            }
          } else {
            dispatch(change('signin', 'captcha_response', ''));
          }

          setTimeout(() => {
            dispatch(stopSubmit('signin', err.errors));
          }, 500);
        })
        .finally(() => {
          setCaptchaSubmitted(false);
        });
    },
    [dispatch, initialCaptchaRequired, signIn]
  );

  const resendVerificationCode = () => {
    dispatch(clearSubmitErrors('signin'));
    dispatch(change('signin', 'otp_attempt', ''));
    dispatch(untouch('signin', 'otp_attempt'));

    setOtpResent(false);
    setResendAttempt(true);

    if (captchaRequired && !captchaSubmitted) {
      /**
       * Once `captchaRequired` is set to true, it will never be set to false.
       * This allows us to continue to enforce the captcha on resend attempts.
       */
      dispatch(change('signin', 'captcha_required', true));
      setShowCaptcha(true);
    } else {
      setSendCodeAfterCaptcha(true);
    }
  };

  useEffect(() => {
    if (sendCodeAfterCaptcha && !prevSendCodeAfterCaptcha.current) {
      setSendCodeAfterCaptcha(false);
      sendVerificationCode(formValues);
    }

    prevSendCodeAfterCaptcha.current = sendCodeAfterCaptcha;
  }, [formValues, sendCodeAfterCaptcha, sendVerificationCode]);

  useEffect(() => {
    if (otpEnabled && !showCaptcha) {
      document.getElementById('user_otp_attempt').focus();
    }
  });

  const captchaVerified = () => {
    dispatch(touch('signin', 'captcha_response'));
    setCaptchaSubmitted(true);
    setSendCodeAfterCaptcha(true);
  };

  return (
    <div className={styles.signinContainer}>
      <div className={styles.signinPageWrapper}>
        <div className={styles.signinSection}>
          <div className={`${styles.eqmLogo} text-center`}>
            <a href="https://www.equitymultiple.com">
              <img src={logo} alt="EquityMultiple" />
            </a>
          </div>

          <Container>
            <OfferingSignUp />
          </Container>

          <div className={styles.loginForm}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {otpEnabled ? (
                <>
                  <p className="text-center">
                    {phoneLastFour && showCaptcha
                      ? 'Please submit the captcha below in order to receive a temporary code to your device.'
                      : 'We sent you a temporary code to your phone number ending in ' +
                        phoneLastFour +
                        '. Code expires in 5 minutes.'}
                  </p>
                  {!showCaptcha && (
                    <Field
                      id="user_otp_attempt"
                      name="otp_attempt"
                      component={ReduxFormInputField}
                      label="Enter Code"
                    />
                  )}
                  <div
                    data-testid="captchaWrapper"
                    className={showCaptcha ? '' : styles.captchaHidden}
                  >
                    <Field
                      name="captcha_response"
                      className="input-fixed-width"
                      component={ReduxFormCaptcha}
                      onChange={captchaVerified}
                    />
                  </div>

                  {otpResent && (
                    <Alert>
                      A one time password has been resent to your mobile phone.
                      Please use a backup code to login if you do not have
                      access to your device.
                    </Alert>
                  )}
                </>
              ) : (
                <>
                  <Field
                    id="email"
                    name="email"
                    component={ReduxFormInputField}
                    label="Email Address"
                  />
                  <Field
                    id="password"
                    name="password"
                    component={ReduxFormPasswordField}
                    label="Password"
                  />
                </>
              )}

              {error && <ErrorLabel message={error} />}

              {!showCaptcha && (
                <>
                  <Button
                    type="submit"
                    loading={fetching}
                    className={styles.signinButton}
                    disabled={showCaptcha}
                  >
                    {otpEnabled ? 'Submit' : 'Sign In'}
                  </Button>

                  <div className={`${styles.optSignin} margin-top-x`}>
                    <span className={styles.cbRememberMe}>
                      {otpEnabled ? (
                        !initialCaptchaRequired && (
                          <Field
                            id="remember_device"
                            name="remember_device"
                            type="checkbox"
                            component={ReduxFormCheckboxField}
                            label="Remember this device for 14 days"
                          />
                        )
                      ) : (
                        <Field
                          id="remember_email"
                          name="remember_email"
                          type="checkbox"
                          component={ReduxFormCheckboxField}
                          label="Remember my email address"
                        />
                      )}
                    </span>

                    <span className={styles.forgot}>
                      {otpEnabled &&
                        (resendVerificationTimer > 0 ? (
                          <div className={styles.resendTimer}>
                            <span className="text-green">
                              Verification code resent.
                            </span>
                            <br />
                            You may resend again in {
                              resendVerificationTimer
                            }{' '}
                            second
                            {resendVerificationTimer > 1 && 's'}.
                          </div>
                        ) : (
                          !initialCaptchaRequired && (
                            <>
                              <button
                                className="text-link margin-top-x"
                                onClick={resendVerificationCode}
                                type="button"
                                disabled={resendVerificationTimer > 0}
                              >
                                Resend verification code
                              </button>
                            </>
                          )
                        ))}
                      {!otpEnabled && (
                        <Link to="/users/password/new">Forgot password?</Link>
                      )}
                    </span>
                  </div>
                </>
              )}

              <div className={styles.cardFooter}>
                {otpEnabled ? (
                  !initialCaptchaRequired && (
                    <>
                      <p>
                        If you are not receiving or having trouble logging in
                        with your temporary code, you may enter a backup code
                        which was provided when two-factor authentication was
                        enabled.
                      </p>
                      <p data-testid="phoneHelpParagraph">
                        If you do not have access to your backup codes or are
                        still unable to log in, please give us a call at{' '}
                        <a href="tel:16468449918">(646) 844-9918</a>.
                      </p>
                    </>
                  )
                ) : (
                  <Link to="/users/signup/start" className={styles.signUpLink}>
                    <span className={styles.account}>
                      Don&#39;t have an account?
                    </span>
                    <i className={`fa fa-arrow-right ${styles.icon}`} />
                  </Link>
                )}
              </div>
            </form>
          </div>

          {!otpEnabled && (
            <div className="text-center margin-xxx">
              <div className={styles.loginButtons}>Or login with Google</div>
              <GoogleButton oauthUrl={googleLoginUrl} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    googleLoginUrl: state.auth.googleLoginUrl,
    fetching: state.auth.fetchingAfterLoginPath,
    formValues: getFormValues('signin')(state)
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormData, Props>({
    form: 'signin',
    destroyOnUnmount: false,
    touchOnBlur: false,
    initialValues: {
      email: utils.getLocalStorage('em_user_email'),
      remember_email: Boolean(utils.getLocalStorage('em_user_email'))
    },
    validate: validateSchema(signInSchema),
    onSubmitFail: scrollToError
  })(SignIn)
);
