import {
  Button,
  Card,
  EMLoadingIcon,
  ErrorLabel
} from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  change,
  Field,
  getFormSyncErrors,
  getFormValues,
  reduxForm,
  SubmitHandler
} from 'redux-form';
import { Offering } from 'types/api/offering';
import { Dispatch } from 'types/redux';

import {
  ReduxFormInputField,
  ReduxFormRadioField,
  ReduxFormSelectField
} from '../../components/ReduxFormFields';
import RichText from '../../components/RichText';
import { getUserAccreditation } from '../../redux/actions/auth';
import {
  agreeConfidentialityAgreement,
  confidentialityAgreementAccepted,
  loadOffering
} from '../../redux/actions/offerings';
import humane from '../../utilities/humane';
import utils from '../../utilities/utils';
import {
  scrollToError,
  throwSubmissionErrors
} from '../../utilities/validation';
import { validateSchema } from '../../utilities/yupValidations';
import { netWorths, portfolioValues } from '../Accounts/contents';
import * as styles from './ConfidentialityAgreement.module.scss';
import { confidentialityAgreementSchema } from './validation';

type Params = {
  closing_id: string;
  offering_id: string;
};

type Props = {
  accountType: string;
  dispatch: Dispatch;
  formValues: {
    scrolledToBottom: boolean;
  };
  fullName: string;
  handleSubmit: SubmitHandler;
  offering: Offering;
  submittingAgreement: boolean;
};

const ConfidentialityAgreement = ({
  handleSubmit,
  submittingAgreement,
  offering,
  accountType,
  formValues,
  dispatch,
  fullName
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  const setAgreementRead = utils.debounce(() => {
    const section = document.getElementById('confidentiality-agreement-block');
    if (section) {
      const scrollHeight =
        section.scrollHeight - section.offsetHeight - section.scrollTop;
      if (scrollHeight < 150) {
        dispatch(change('confidentialityAgreement', 'scrolledToBottom', true));
        // Both functions use each other so there's no way around this error
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        removeScrollListener();
      }
    }
  }, 100);

  const removeScrollListener = () => {
    const section = document.getElementById('confidentiality-agreement-block');
    if (section) {
      section.removeEventListener('scroll', setAgreementRead);
    }
  };

  useEffect(() => {
    dispatch(confidentialityAgreementAccepted(params.offering_id)).then(
      (accepted: boolean) => {
        if (accepted) {
          navigate(
            `/invest/${params.offering_id}/closings/${params.closing_id}`
          );
        }
      }
    );
    dispatch(getUserAccreditation());
    dispatch(loadOffering(params.offering_id)).then(() => {
      const section = document.getElementById(
        'confidentiality-agreement-block'
      );
      if (section) {
        section.addEventListener('scroll', setAgreementRead);
      }
    });

    return () => {
      removeScrollListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.offering_id, params.closing_id]);

  const handleFormSubmit = values => {
    return dispatch(agreeConfidentialityAgreement(offering.id, values))
      .then(res => {
        if (res) {
          if (res.confidentiality_accepted) {
            navigate(`/invest/${offering.id}/closings/${params.closing_id}`);
          } else {
            humane.error(
              `You must be a Qualified Purchaser with more than $5M Net Worth to view ${offering.title}`
            );
            navigate('/');
          }
        }
      })
      .catch(res => throwSubmissionErrors(res));
  };

  return !offering ? (
    <EMLoadingIcon />
  ) : (
    <div>
      <Container className="container-narrow">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Card className={styles.card}>
            <h4>
              To proceed, please review and complete the information below
            </h4>
            <p>
              In order to view <strong>{offering.title}</strong> we ask that you
              confirm your status as a Qualified Purchaser and sign a
              confidentiality agreement.
            </p>

            <h6>1. Are you a Qualified Purchaser?</h6>
            <p>
              If you plan to invest personally or on behalf of a trust or
              family-owned entity, do you or the investment vehicle own at least
              $5 million in invested assets?
            </p>
            <Row />
            <Col md={6} className="overflow-visible">
              <Field
                type="radio"
                name="user.qualified_purchaser"
                id="user_qualified_purchaser_yes"
                value="true"
                component={ReduxFormRadioField}
                label="Yes"
                hideError
              />
              <Field
                type="radio"
                name="user.qualified_purchaser"
                id="user_qualified_purchaser_no"
                value="false"
                component={ReduxFormRadioField}
                label="No"
              />
            </Col>

            <h6>2. Update your net worth</h6>
            <p>Please select your net worth</p>
            <Row>
              <Col md={6} className="overflow-visible">
                {accountType === 'entity' ? (
                  <Field
                    name="user.portfolio_value"
                    id="user_portfolio_value"
                    component={ReduxFormSelectField}
                    label="Portfolio Value"
                    options={portfolioValues.map(key => ({
                      label: key,
                      value: key
                    }))}
                  />
                ) : (
                  <Field
                    name="user.net_worth"
                    id="user_net_worth"
                    component={ReduxFormSelectField}
                    label="Net Worth"
                    options={netWorths.map(key => ({
                      label: key,
                      value: key
                    }))}
                  />
                )}
              </Col>
            </Row>

            <h6>3. Confidentiality Agreement</h6>
            <div id="confidentiality-agreement-block">
              <RichText text={offering.confidentiality_status} sanitize />
              <div className={styles.signatureBox}>
                <p>
                  IT IS IMPORTANT THAT YOU READ CAREFULLY AND UNDERSTAND THIS
                  AGREEMENT. BY TYPING YOUR FULL NAME AND CLICKING THE “SUBMIT”
                  BUTTON LOCATED ON THIS PAGE, YOU AND ANY LEGAL ENTITY YOU
                  REPRESENT WILL BE BOUND BY THIS AGREEMENT. IF YOU DO NOT AGREE
                  WITH ALL OF THE TERMS OF THIS AGREEMENT AND DO NOT AGREE TO BE
                  BOUND BY THIS AGREEMENT, PLEASE DO NOT CLICK THE “SUBMIT”
                  BUTTON. IF YOU DO NOT ACCEPT THIS AGREEMENT, YOU MAY NOT USE
                  OR ACCESS THE PROPRIETARY INFORMATION.
                </p>
                <Row>
                  <Col md={6}>
                    <h5>{fullName}</h5>
                    <Field
                      name="user.signature"
                      id="signature"
                      component={ReduxFormInputField}
                      label="Full Name"
                    />
                  </Col>
                </Row>
              </div>
            </div>
            {!formValues.scrolledToBottom && (
              <ErrorLabel message="You must read the entire confidentiality agreement. Please scroll to the bottom of the agreement." />
            )}
            <div>
              <Button
                type="submit"
                className={styles.submitButton}
                loading={submittingAgreement}
              >
                Submit
              </Button>
              <Link className={styles.backLink} to="/">
                Return to Browse Offerings
              </Link>
            </div>
          </Card>
        </form>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  const initialValues = {
    user: state.auth.accreditation || {},
    scrolledToBottom: false
  };
  initialValues.scrolledToBottom = false;
  const user = state.auth.user;
  const fullName = user?.first_name.trim() + ' ' + user.last_name.trim();
  initialValues.user.fullName = fullName;
  return {
    offering: state.offerings.offeringData,
    submittingAgreement: state.offerings.submittingAgreement,
    fullName,
    accountType: state?.auth?.accreditation?.investment_account_type,
    formErrors: getFormSyncErrors('confidentialityAgreement')(state),
    formValues: getFormValues('confidentialityAgreement')(state),
    initialValues
  };
};

export default connect(mapStateToProps)(
  reduxForm({
    form: 'confidentialityAgreement',
    enableReinitialize: true,
    touchOnBlur: false,
    onSubmitFail: scrollToError,
    validate: validateSchema(confidentialityAgreementSchema)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
  })(ConfidentialityAgreement)
);
