// Validation helper functions
import { FormErrors, SubmissionError } from 'redux-form';

export const scrollToError = () => {
  setTimeout(() => {
    const errorDiv = document.querySelector('.fieldWithErrors + div');
    if (errorDiv) {
      const errorTop =
        errorDiv.getBoundingClientRect().top + window.scrollY - 150;
      if (window.scrollY > errorTop) {
        window.scrollTo({
          top: errorTop,
          behavior: 'smooth'
        });
      }
    }
  }, 0);
};

// TODO: Remove this and rename throwReduxHookFormErrors once redux-form is no longer in use
export const throwSubmissionErrors = response => {
  const errors = response?.body?.errors;
  const errorMessage = response?.body?.message;
  let formErrors: FormErrors = {};

  if (errors && Object.keys(errors).length > 0) formErrors = errors;
  // Don't populate _error if there are other errors, since the value is most likely just a redundant "Validation errors" message
  else if (errorMessage) formErrors._error = errorMessage;

  throw new SubmissionError(formErrors);
};

// Helper function to recursively handle nested errors in server responses
const processErrors = (errors, setError, parentField = '') => {
  for (const field in errors) {
    if (Object.prototype.hasOwnProperty.call(errors, field)) {
      const errorMessages = errors[field];

      const fullFieldName = parentField ? `${parentField}.${field}` : field;

      if (Array.isArray(errorMessages) && errorMessages.length > 0) {
        // If it's an array, call setError on the first error message
        errorMessages.forEach(errorMessage => {
          setError(fullFieldName, {
            type: 'server',
            message: errorMessage
          });
        });
      } else if (typeof errorMessages === 'object' && errorMessages !== null) {
        // If it's a nested object, recursively process it
        processErrors(errorMessages, setError, fullFieldName);
      }
    }
  }
};

export const throwReactHookFormSubmissionErrors = (response, setError) => {
  const errors = response?.body?.errors;
  const singleErrorMessage = response?.body?.message;

  if (errors && Object.keys(errors).length > 0) {
    processErrors(errors, setError);
  } else if (singleErrorMessage) {
    setError('root.serverError', {
      type: 'server',
      message: singleErrorMessage
    });
  }
};

export const betweenMinMax = (minValue, maxValue) => (value, previousValue) =>
  value >= minValue && value <= maxValue ? value : previousValue;
