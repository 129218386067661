import { Button, Card } from '@equitymultiple/react-eui';
import { ReduxFormRadioField } from 'components/ReduxFormFields';
import { distributionReinvestmentElectionsSchema } from 'containers/Investment/validations';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  loadDistributionReinvestmentInOffering,
  updateDistributionReinvestmentInOffering
} from 'redux/actions/offerings';
import {
  change,
  Field,
  Form,
  getFormValues,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import { SignatureStyle } from 'types/api/investment';
import { DistributionReinvestment } from 'types/api/offering';
import { Dispatch } from 'types/redux';
import utils from 'utilities/utils';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { AcknowledgeAndSign } from '../Sign/components/AcknowledgeAndSign/AcknowledgeAndSign';
import * as styles from './DistributionReinvestmentElections.module.scss';

const LoadingSkeleton = () => (
  <div data-testid="loadingSkeleton">
    <h3>
      <Skeleton width="60%" />
    </h3>
    <Row>
      <Col md={4}>
        <p>
          <Skeleton width="60%" />
        </p>
      </Col>
      <Col md={4}>
        <p>
          <Skeleton width="90%" />
        </p>
      </Col>
      <Col md={4}>
        <p>
          <Skeleton width="80%" />
        </p>
      </Col>
    </Row>
    <h5 className="margin-top-xx margin-xxx">
      <Skeleton width="50%" />
    </h5>
    <p className="margin-xxx">
      <Skeleton width="90%" />
    </p>
    <p className="margin-xxx">
      <Skeleton width="90%" />
    </p>
  </div>
);

interface SubmitValues {
  datetime?: string;
  investment_id: number;
  opt_in: boolean;
  signature_style?: SignatureStyle;
}

type Params = {
  offering_id: string;
};

type CustomProps = {
  dispatch: Dispatch;
  distributionReinvestment: DistributionReinvestment;
  formValues: {
    documents: {
      string: boolean;
    };
    opt_in: boolean;
    pledge1: boolean;
    pledge2: boolean;
    pledge3: boolean;
    signature_style: string;
  };
  loading: boolean;
  sending: boolean;
};

type Props = InjectedFormProps<object, CustomProps> & CustomProps;

const DistributionReinvestmentElections = ({
  anyTouched,
  dispatch,
  distributionReinvestment,
  formValues,
  handleSubmit,
  loading,
  sending
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();
  const madeSelection = formValues?.opt_in !== undefined;
  const optingIn = !!formValues?.opt_in;
  const investmentAmount = parseFloat(distributionReinvestment?.amount);

  useEffect(() => {
    document.title = 'Investment Elections | EquityMultiple';

    dispatch(loadDistributionReinvestmentInOffering(params.offering_id)).catch(
      () => {
        navigate('/', { replace: true });
      }
    );
  }, [dispatch, params.offering_id, navigate]);

  const onSubmit = values => {
    const submitValues: SubmitValues = {
      investment_id: distributionReinvestment.id,
      opt_in: values.opt_in
    };

    if (submitValues.opt_in) {
      submitValues.datetime = new Date().toISOString();
      submitValues.signature_style = values.signature_style;
    }

    return dispatch(
      updateDistributionReinvestmentInOffering(params.offering_id, submitValues)
    )
      .then(() => {
        if (submitValues.opt_in) {
          navigate(
            `/invest/${distributionReinvestment.destination_closing.id}/investment/${distributionReinvestment.id}/confirm`
          );
        } else {
          navigate(
            `/invest/${distributionReinvestment.destination_offering.id}/closings/${distributionReinvestment.destination_closing.id}`
          );
        }
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  const setDownloadedField = (documentIndex: number) => {
    dispatch(
      change(
        'distributionReinvestmentElections',
        `documents.doc${documentIndex}`,
        true
      )
    );
  };

  const documentsNotDownloaded =
    formValues?.documents &&
    Object.keys(formValues.documents).some(
      document => !formValues.documents[document]
    );

  return (
    <Container className="container-narrow">
      {loading ? (
        <Card>
          <LoadingSkeleton />
        </Card>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <h3 className="margin-top-0">
              Reinvest your upcoming REM Fund Distribution into the Ascent Fund
            </h3>
            <div data-testid="topDetails">
              <Row>
                <Col md={4}>
                  <div className="text-label">Source Investment</div>
                  <p>{distributionReinvestment?.source_offering.title}</p>
                </Col>
                <Col md={4}>
                  <div className="text-label">Original Investment Amount</div>
                  <p>{utils.formatCurrency(investmentAmount)}</p>
                </Col>
                <Col md={4}>
                  <div className="text-label">Destination Offering</div>
                  <p>{distributionReinvestment?.destination_offering.title}</p>
                </Col>
              </Row>
            </div>

            <h3>Reinvestment Options</h3>
            <Field
              type="radio"
              name="opt_in"
              id="opt_in"
              label="Reinvest Capital Balance"
              value="true"
              hideError
              component={ReduxFormRadioField}
              normalize={utils.normalizeBoolean}
              format={utils.formatBoolean}
            />
            <Field
              type="radio"
              name="opt_in"
              id="opt_out"
              label="Opt Out"
              value="false"
              component={ReduxFormRadioField}
              normalize={utils.normalizeBoolean}
              format={utils.formatBoolean}
            />
          </Card>
          {optingIn && (
            <Card data-testid="sign">
              <h3 className="margin-top-0">Sign</h3>
              <p className="margin-xx">
                Complete this step to secure your spot in the investment.
              </p>
              <AcknowledgeAndSign
                anyTouched={anyTouched}
                setDownloadedField={setDownloadedField}
                formValues={formValues}
                offeringId={Number(params.offering_id)}
                signRequiredDocuments={
                  distributionReinvestment?.destination_offering
                    .sign_required_documents
                }
                documentsNotDownloaded={documentsNotDownloaded}
              />
            </Card>
          )}

          {madeSelection && (
            <Card data-testid="summary">
              <h3 className="margin-top-0">Summary</h3>

              <p className="margin-xx">
                {optingIn
                  ? 'You are going to reinvest your principal balance to the next destination offering, and withdraw accrued interest.'
                  : 'You are going to opt-out of reinvesting.'}
              </p>

              <div data-testid="summaryDetails">
                {optingIn ? (
                  <>
                    <Row>
                      <Col md={6}>
                        <div className="text-label">Rollover Total</div>
                        <p>{utils.formatCurrency(investmentAmount)}</p>
                      </Col>
                      <Col md={6}>
                        <div className="text-label">Balance Rollover</div>
                        <p>{utils.formatCurrency(investmentAmount)}</p>
                      </Col>
                    </Row>
                    <hr className={styles.rowSeparator} />
                    <Row>
                      <Col md={6}>
                        <div className="text-label">Interest Withdrawal</div>
                        <p className="italic">
                          To be updated upon buyout close
                        </p>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col sm={6}>
                      <div className="text-label">Balance Withdrawal</div>
                      <p>{utils.formatCurrency(investmentAmount)}</p>
                    </Col>
                    <Col sm={6}>
                      <div className="text-label">Interest Withdrawal</div>
                      <p className="italic">To be updated upon buyout close</p>
                    </Col>
                  </Row>
                )}
              </div>

              <div className={styles.buttonWrap}>
                <p className="italic">
                  *Withdrawal amounts will not be reinvested into the Ascent
                  Fund
                </p>
                <Button
                  type="submit"
                  loading={sending}
                  data-testid="submitButton"
                >
                  {optingIn ? 'Sign and Save' : 'Save'}
                </Button>
              </div>
            </Card>
          )}
        </Form>
      )}
    </Container>
  );
};

function mapStateToProps(store) {
  const userRegisteredName = `${store.auth.user.first_name} ${store.auth.user.last_name}`;

  return {
    distributionReinvestment: store.offerings.distributionReinvestment,
    loading: store.offerings.loadingReinvestment,
    sending: store.offerings.submitting,
    initialValues: {
      registeredName: userRegisteredName,
      signature_style: 'Kalam'
    },
    formValues: getFormValues('distributionReinvestmentElections')(store)
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, CustomProps>({
    form: 'distributionReinvestmentElections',
    enableReinitialize: true,
    touchOnBlur: false,
    validate: validateSchema(distributionReinvestmentElectionsSchema),
    onSubmitFail: scrollToError
  })(DistributionReinvestmentElections)
);
