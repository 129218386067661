import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import {
  ReduxFormDateSelectField,
  ReduxFormInputField,
  ReduxFormSelectField
} from 'components/ReduxFormFields';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { updateUserProfile } from 'redux/actions/user-settings';
import { Field, Form, formValueSelector, reduxForm } from 'redux-form';
import { ssn, ssnMaskOptions } from 'utilities/masks';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import {
  countryOptions,
  occupationOptions,
  residenceStatusOptions
} from '../content';
import { getUserAttributes, profileFormValues } from '../helpers';
import { SharedProfileValues } from '../types';
import { profileSchema } from '../validation';

const Identity = ({
  dispatch,
  error,
  handleSubmit,
  residenceStatus,
  submitting
}: SharedProfileValues) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Get Ready to Invest | EquityMultiple';
  }, []);

  const onSubmit = (values: object) => {
    const submitValues = profileFormValues(values, [
      'investor_profile_attributes.dob',
      'investor_profile_attributes.residence_status',
      'investor_profile_attributes.country_of_citizenship',
      'investor_profile_attributes.occupation',
      'investor_profile_attributes.ssn'
    ]);

    return dispatch(updateUserProfile(submitValues))
      .then(() => {
        dispatch(loadAuth()).then(() => {
          navigate('/users/profile/address');
        });
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  return (
    <AccountProgressContainer signupStage="profile" showSkipLink>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-x">Let's verify your identity</h3>
        <p className="margin-xx">
          We collect this information to comply with federal regulation such as
          know-your-customer (KYC) and anti-money laundering (AML) laws.
        </p>
        <Field
          id="dob"
          component={ReduxFormDateSelectField}
          name="investor_profile_attributes.dob"
          label="Date of Birth"
        />
        <Field
          id="ssn"
          component={ReduxFormInputField}
          name="investor_profile_attributes.ssn"
          label="Social Security Number"
          mask={ssn}
          inputMaskOptions={ssnMaskOptions}
        />
        <Field
          id="residence_status"
          component={ReduxFormSelectField}
          name="investor_profile_attributes.residence_status"
          label="Residence Status"
          options={residenceStatusOptions}
        />
        {residenceStatus && residenceStatus !== 'U.S. Citizen' && (
          <Field
            id="country_of_citizenship"
            component={ReduxFormSelectField}
            name="investor_profile_attributes.country_of_citizenship"
            label="Country of Citizenship"
            options={countryOptions}
          />
        )}
        <Field
          id="occupation"
          component={ReduxFormSelectField}
          name="investor_profile_attributes.occupation"
          label="Occupation"
          options={occupationOptions}
        />

        {error && <ErrorLabel message={error} className="margin-xxx" />}

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            loading={submitting}
            variant="orange"
            className="button-fixed-width margin-top-xx"
          >
            Continue
          </Button>
          <Button
            disabled={submitting}
            variant="outlined"
            data-testid="backLink"
            className="button-fixed-width margin-top-xx arrowBackButton"
            wrapper={<Link to="/users/profile/name" />}
          >
            <Back />
          </Button>
        </div>
      </Form>
    </AccountProgressContainer>
  );
};

const selector = formValueSelector('kyc');

function mapStateToProps(store) {
  const residenceStatus = selector(
    store,
    'investor_profile_attributes.residence_status'
  );

  return {
    initialValues: getUserAttributes(store.auth.user),
    submitting: store.userSettings.updatingUserProfile,
    residenceStatus
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'kyc',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(profileSchema)
  })(Identity)
);
