import AccountProgress from 'components/AccountProgress/AccountProgress';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  loadActiveOfferings,
  loadFundedOfferings,
  loadMarketingData,
  loadTrackRecordData
} from 'redux/actions/invest';
import {
  InvestArticles,
  InvestOfferings,
  InvestTrackRecord
} from 'types/api/offering';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import utils from 'utilities/utils';

import { WelcomeLetter } from './components';
import OfferingList from './components/OfferingList/OfferingList';
import * as styles from './Invest.module.scss';
import * as sharedStyles from './InvestShared.module.scss';

type Props = {
  articles: InvestArticles;
  dispatch: Dispatch;
  fundedPagination: {
    page: number;
    pageSize: number;
    total: number;
  };
  loadingActive: boolean;
  loadingFunded: boolean;
  loadingTrackRecord: boolean;
  offerings: InvestOfferings;
  pillarOrder: string[];
  trackRecord: InvestTrackRecord;
  trackRecordSummary: string;
  user: User;
};

const Invest = ({
  articles,
  dispatch,
  fundedPagination,
  loadingActive,
  loadingFunded,
  loadingTrackRecord,
  offerings,
  pillarOrder,
  trackRecord,
  trackRecordSummary,
  user
}: Props) => {
  const location = useLocation();

  let showWelcomeLetter = false;
  const query = queryString.parse(location.search);
  if (query?.show === 'welcome' && user.investor_profile.stage === 'invest') {
    const welcomeLetterShown =
      utils.getCookie('welcome-letter-shown') === user.id.toString();
    if (!welcomeLetterShown) showWelcomeLetter = true;
  }

  useEffect(() => {
    document.title = 'Invest | EquityMultiple';
    document.querySelector('body').style.backgroundColor = '#f8f8f8';

    dispatch(loadActiveOfferings());
    dispatch(loadMarketingData());
    dispatch(loadTrackRecordData());
    dispatch(loadFundedOfferings(1));
  }, [dispatch]);

  const loadMoreFundedOfferings = () => {
    dispatch(loadFundedOfferings(fundedPagination.page + 1));
    EmAnalytics.track('Clicked Load More Button', 'Investing');
  };

  const remainingOfferings =
    fundedPagination.total - fundedPagination.pageSize * fundedPagination.page;
  const hasMoreFundedOfferings = remainingOfferings > 0;

  const showAccountProgress = user?.investor_profile?.stage === 'activate';

  return (
    <div>
      {showWelcomeLetter && (
        <WelcomeLetter
          name={user.first_name}
          userId={user.id}
          irRep={user.investor_profile?.representative_meeting_link}
        />
      )}
      <Container className="container-wide">
        <div className={styles.wrap}>
          {showAccountProgress && (
            <aside className={styles.sidebar}>
              <AccountProgress user={user} signupStage="invest" />
            </aside>
          )}
          <section className={styles.mainContent}>
            <div className={sharedStyles.sectionHeadingBlock}>
              <h1 className="margin-top-0 margin-x">
                A Unique Wealth Generation Ecosystem
              </h1>
              <div
                className={`section-subheading ${sharedStyles.sectionSubheading}`}
              >
                Three complementary pillars to support the evolution of your
                portfolio.
              </div>
              <hr />
            </div>
            <OfferingList
              pillarOrder={pillarOrder}
              articles={articles}
              loadingActive={loadingActive}
              loadingFunded={loadingFunded}
              loadMoreFundedOfferings={loadMoreFundedOfferings}
              showLoadMoreFundedOfferingsButton={hasMoreFundedOfferings}
              loadingTrackRecord={loadingTrackRecord}
              offerings={offerings}
              trackRecord={trackRecord}
              trackRecordSummary={trackRecordSummary}
              user={user}
            />
          </section>
        </div>
      </Container>
    </div>
  );
};

function mapStateToProps(store) {
  return {
    pillarOrder: store.invest.offerings.pillarOrder,
    articles: store.invest.articles,
    fundedPagination: store.invest.fundedPagination,
    loadingActive: store.invest.loadingActive,
    loadingFunded: store.invest.loadingFunded,
    loadingTrackRecord: store.invest.loadingTrackRecord,
    offerings: store.invest.offerings,
    trackRecord: store.invest.trackRecord,
    trackRecordSummary: store.invest.trackRecordSummary,
    user: store.auth.user
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default connect(mapStateToProps)(Invest);
