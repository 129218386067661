import { Button, EMLoadingIcon } from '@equitymultiple/react-eui';
import { UpdateAlert } from 'containers/UserUpdateAlerts/types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  loadInvestmentAccounts,
  updateInvestmentAccount
} from 'redux/actions/account';
import { updateUserProfile } from 'redux/actions/user-settings';
import { updateUserUpdateAlert } from 'redux/actions/user-update-alert';
import { User } from 'types/api/user';
import { Dispatch } from 'types/redux';
import EmAnalytics from 'utilities/em_analytics';
import { handleErrorResponse } from 'utilities/errorHandlers';

import * as sharedStyles from '../../UserUpdateAlertsShared.module.scss';
import * as styles from './AnnualAddressVerification.module.scss';
import EntityAddressVerificationForm from './EntityAddressVerificationForm/EntityAddressVerificationForm';
import {
  confirmEntityWhetherDetailsMatch,
  confirmWhetherUserDetailsMatch,
  formatListOfEntityAccountsIfSelectedForNameChange,
  formatListOfInvestmentAccountsIfSelectedForUpdate,
  hasUserPreviouslyUpdatedAccountDetailInformation,
  investmentAccountCounter,
  locatePreviouslyEnteredAccountDetails,
  prepareRowInformation
} from './helpers';
import PersonalAccountAddressVerificationForm from './PersonalAccountAddressVerificationForm';

interface Props {
  dispatch: Dispatch;
  handleUpdateAlertStates: () => void;
  user: User;
  userUpdateAlert: UpdateAlert;
}

const createAddress = address => (
  <>
    <div>{address.address}</div>
    <div>{address.address2}</div>
    <div>
      {address.city +
        ', ' +
        (address.region || address.state) +
        ' ' +
        address.postal_code}
    </div>
  </>
);

const AnnualAddressVerification = ({
  dispatch,
  user,
  handleUpdateAlertStates,
  userUpdateAlert
}: Props) => {
  const navigate = useNavigate();

  const [investmentAccounts, setInvestmentAccounts] = useState([]);
  const [entityAccountsSelectedForUpdate, setEntityAccountsSelectedForUpdate] =
    useState([]);
  const [
    accountsSelectedForEntityNameChange,
    setAccountsSelectedForEntityNameChange
  ] = useState([]);
  const [updatedUserInformation, setUpdatedUserInformation] = useState({});
  const [initiallyLoadedUserInformation, setInitiallyLoadedUserInformation] =
    useState({});
  const [accountSelectedForReview, setAccountSelectedForReview] = useState();

  useEffect(() => {
    dispatch(loadInvestmentAccounts())
      .then(res => {
        setInvestmentAccounts(res);
      })
      .catch(err => handleErrorResponse(navigate, err));
    setInitiallyLoadedUserInformation(user);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Manage user selections forward and back
  const manageUserElectionToUpdateAccountDetails = accountId => {
    setAccountSelectedForReview(accountId);
  };

  const visitPreviousPage = () => {
    setAccountSelectedForReview(null);
  };

  // Manage updating state for changes made to user level details
  const manageUserSubmit = userAccount => {
    setAccountSelectedForReview(null);
    if (
      !confirmWhetherUserDetailsMatch(
        userAccount.user_address_verification,
        user
      )
    )
      setUpdatedUserInformation({
        ...userAccount.user_address_verification,
        address: {
          ...userAccount.user_address_verification
        }
      });
  };

  // Manage user's interest in updating their entity's name
  const noteUserInterestInUpdatingEntityName = referenceId => {
    setAccountsSelectedForEntityNameChange([
      ...accountsSelectedForEntityNameChange,
      referenceId
    ]);
  };

  const locateIndexOfEntityAccountWithinEntityAccountsStagedForUpdate = id => {
    const copyOfStateArray = [...entityAccountsSelectedForUpdate];
    return copyOfStateArray.map(element => element.id).indexOf(id);
  };

  const removeElementFromUpdatedAddressesIfItAlreadyExistsInEntityAccountList =
    iA => {
      setEntityAccountsSelectedForUpdate(
        [...entityAccountsSelectedForUpdate]
          .filter(el => el.id !== iA.id)
          .concat([iA])
      );
    };

  const manageEntityAccountDetailUpdateProcess = investmentAccount => {
    const finalAccounts = [];
    investmentAccounts.forEach(account => {
      if (
        account.id === investmentAccount.id &&
        !confirmEntityWhetherDetailsMatch(investmentAccount, account)
      ) {
        if (
          locateIndexOfEntityAccountWithinEntityAccountsStagedForUpdate(
            investmentAccount.id
          ) >= 0
        )
          removeElementFromUpdatedAddressesIfItAlreadyExistsInEntityAccountList(
            investmentAccount
          );
        else
          setEntityAccountsSelectedForUpdate([
            ...entityAccountsSelectedForUpdate,
            investmentAccount
          ]);
        finalAccounts.push(investmentAccount);
      } else {
        finalAccounts.push(account);
      }
    });
    return finalAccounts;
  };

  // Manage updating state for changes made to entity-account level details
  const manageEntityAccountSubmit = values => {
    setAccountSelectedForReview(null);
    setInvestmentAccounts(
      manageEntityAccountDetailUpdateProcess(values.investment_account)
    );
  };

  const manageUpdatingUserEntityAccountInformation = entityAccountsToUpdate => {
    entityAccountsToUpdate.forEach(entityAccount => {
      dispatch(updateInvestmentAccount({ investment_account: entityAccount }));
    });
  };

  const manageUpdatingUserPersonalInformation = personalInformation => {
    dispatch(updateUserProfile(personalInformation));
  };

  const manageUpdatingAppropriateValuesAndSendingAppropriateEvents = () => {
    // update db values for entity accounts that have been selected for update
    if (entityAccountsSelectedForUpdate.length > 0)
      manageUpdatingUserEntityAccountInformation(
        entityAccountsSelectedForUpdate
      );

    // update db value for user if phone number or address updated
    if (Object.keys(updatedUserInformation).length > 0)
      manageUpdatingUserPersonalInformation(updatedUserInformation);

    // send update alert update

    if (
      entityAccountsSelectedForUpdate.length > 0 ||
      Object.keys(updatedUserInformation).length > 0
    )
      userUpdateAlert.action = 'User updated information';
    else userUpdateAlert.action = 'User confirmed information';

    dispatch(updateUserUpdateAlert(userUpdateAlert)).then(
      completedUpdateAlert => {
        if (completedUpdateAlert.date_considered_complete) {
          const year = new Date().getFullYear();
          const taxDay = new Date(year, 4, 15);
          const today = new Date();
          const responseProperty = {
            year: today < taxDay ? year - 1 : year,
            user_requests_entity_name_change:
              accountsSelectedForEntityNameChange.length > 0, // true or false
            names_of_entity_accounts_user_requests_name_change_for:
              formatListOfEntityAccountsIfSelectedForNameChange(
                investmentAccounts,
                accountsSelectedForEntityNameChange
              ),
            list_of_accounts_with_updated_details:
              formatListOfInvestmentAccountsIfSelectedForUpdate(
                investmentAccounts,
                updatedUserInformation,
                entityAccountsSelectedForUpdate
              ),
            address_updated: !!(
              entityAccountsSelectedForUpdate.length > 0 ||
              Object.keys(updatedUserInformation).length > 0
            )
          };

          EmAnalytics.track(
            'Annual Address Verification Completed',
            'Update Alert',
            responseProperty
          );
          handleUpdateAlertStates();
        }
      }
    );
  };

  // Manage user's submission of information through updating database values
  const manageClick = () => {
    manageUpdatingAppropriateValuesAndSendingAppropriateEvents();
  };

  const createAddressRows = formattedInvestmentAccounts => {
    return formattedInvestmentAccounts.map(account => (
      <div className={styles.accountListItem} key={account.accountId}>
        <div className={`${styles.tableCell} ${styles.accountInfo}`}>
          <div className="size-16 text-dark">{account.entityName}</div>
          {account.subTitle}
          <br />
          {account.investmentCount + ' Investments'}
        </div>
        <div className={`${styles.tableCell} ${styles.address}`}>
          {createAddress(account.address)}
        </div>
        <button
          onClick={() =>
            manageUserElectionToUpdateAccountDetails(account.referenceId)
          }
          className={`text-link float-right size-16 ${styles.updateButton}`}
          type="button"
        >
          {hasUserPreviouslyUpdatedAccountDetailInformation(
            account,
            entityAccountsSelectedForUpdate,
            updatedUserInformation
          )
            ? 'Edit'
            : 'Update'}
        </button>
      </div>
    ));
  };

  const formattedInvestmentAccounts = prepareRowInformation(
    investmentAccounts,
    Object.keys(updatedUserInformation).length > 0
      ? updatedUserInformation
      : initiallyLoadedUserInformation
  );

  return !formattedInvestmentAccounts ||
    formattedInvestmentAccounts.length === 0 ? (
    <EMLoadingIcon />
  ) : (
    <>
      {!accountSelectedForReview ? (
        <>
          <h4 className="margin-x">Contact Information Review</h4>
          <p className="size-16">
            Please review the following Investment Account
            {investmentAccountCounter(investmentAccounts) > 1 ? 's' : ''} to
            ensure that all information is up-to-date and accurate.
          </p>
          <div className={styles.accountList}>
            <hr className={sharedStyles.hr} />
            {createAddressRows(formattedInvestmentAccounts)}
          </div>
          <div className={`${sharedStyles.footer} clearfix-after`}>
            <div className={sharedStyles.disclaimer}>
              By clicking verify you represent that this information is
              up-to-date and accurate.
            </div>
            <Button style={{ width: 200 }} onClick={manageClick}>
              Verify
            </Button>
          </div>
        </>
      ) : Number.isNaN(parseInt(accountSelectedForReview)) ? (
        <PersonalAccountAddressVerificationForm
          investmentAccountTitle={formattedInvestmentAccounts[0].subTitle}
          previousWizard={visitPreviousPage}
          onSubmit={manageUserSubmit}
          previouslyEnteredUpdatedUserInformation={
            Object.keys(updatedUserInformation).length > 0
              ? updatedUserInformation
              : null
          }
        />
      ) : (
        <EntityAddressVerificationForm
          accountSelectedForUpdate={accountSelectedForReview}
          previouslyEnteredAccountDetails={locatePreviouslyEnteredAccountDetails(
            entityAccountsSelectedForUpdate,
            accountSelectedForReview
          )}
          noteUserInterestInUpdatingEntityName={
            noteUserInterestInUpdatingEntityName
          }
          userElectedToUpdateInvestmentAccountNamePreviously={accountsSelectedForEntityNameChange.includes(
            accountSelectedForReview
          )}
          onSubmit={manageEntityAccountSubmit}
          previousWizard={visitPreviousPage}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  let taxUpdateAlert;
  if (state.userUpdateAlerts.userUpdateAlerts) {
    taxUpdateAlert = state.userUpdateAlerts.userUpdateAlerts.find(
      updateAlert => updateAlert.title === 'tax_address_verification'
    );
  }

  return {
    investmentAccounts: state.account.investmentAccounts,
    user: state.auth.user,
    userUpdateAlert: taxUpdateAlert
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps)(AnnualAddressVerification);
