import { Button, Card, EMLoadingIcon } from '@equitymultiple/react-eui';
import React, { useEffect } from 'react';
import { Container } from 'react-grid-system';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  change,
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm
} from 'redux-form';
import { InvestmentAccount } from 'types/api/account';
import { Closing } from 'types/api/closing';
import { Investment } from 'types/api/investment';
import { Offering } from 'types/api/offering';
import { Dispatch } from 'types/redux';

import {
  ReduxFormInputField,
  ReduxFormRadioField
} from '../../../components/ReduxFormFields';
import { loadCompletedAccounts } from '../../../redux/actions/investment-account';
import {
  cancelInvestment,
  loadInvestment
} from '../../../redux/actions/investments';
import { loadClosing } from '../../../redux/actions/offerings';
import EmAnalytics from '../../../utilities/em_analytics';
import { handleErrorResponse } from '../../../utilities/errorHandlers';
import humane from '../../../utilities/humane';
import {
  scrollToError,
  throwSubmissionErrors
} from '../../../utilities/validation';
import { validateSchema } from '../../../utilities/yupValidations';
import InvestmentStatus from '../components/InvestmentStatus/InvestmentStatus';
import InvestmentTitle from '../components/InvestmentTitle/InvestmentTitle';
import { cancelInvestmentSchema } from '../validations';

const callIrLabel =
  "I don't understand this offering / This offering is confusing to me.";

const getCancelDetailLabel = (cancelReason: string) => {
  switch (cancelReason) {
    case "I don't have the capital to fund this investment on time.":
      return 'Is there anything we can do?';
    case "I don't like this offering.":
      return "Tell us why you don't like this offering";
    case 'Other':
      return 'Please Describe';
    case callIrLabel:
      return 'Call IR';
  }
  return '';
};

type Params = {
  closing_id: string;
  investment_id: string;
  offering_id: string;
};

interface CustomProps {
  accounts: InvestmentAccount[];
  cancelReason: string;
  closing: Closing;
  dispatch: Dispatch;
  investment: Investment;
  offering: Offering;
  sending: boolean;
}

type Props = InjectedFormProps<object, CustomProps> & CustomProps;

const CancelInvestment = ({
  accounts,
  dispatch,
  handleSubmit,
  offering,
  closing,
  investment,
  cancelReason,
  sending
}: Props) => {
  const navigate = useNavigate();
  const params = useParams<Params>();

  useEffect(() => {
    const newInvestmentRoute = `/invest/${params.closing_id}/investment/new`;

    if (!params.closing_id) {
      navigate('/');
    } else if (!params.investment_id) {
      navigate(newInvestmentRoute);
    } else {
      dispatch(loadClosing(params.closing_id)).catch(error =>
        handleErrorResponse(navigate, error)
      );
      dispatch(loadInvestment(params.investment_id)).catch(error =>
        handleErrorResponse(navigate, error)
      );
      dispatch(loadCompletedAccounts()).catch(error =>
        handleErrorResponse(navigate, error)
      );
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = values => {
    return dispatch(cancelInvestment(investment.id, values))
      .then(() => {
        EmAnalytics.track('Investment Cancelled', 'Investment', {
          offering: offering.title,
          closing: closing.name,
          amount: investment.amount,
          first_time_investment: investment.first_time_investment,
          investment_id: investment.id.toString(),
          ...values
        });
        navigate(`/invest/${offering.id}/closings/${closing.id}/`);
        humane.notice('You have successfully cancelled your investment');
      })
      .catch(error => {
        throwSubmissionErrors(error);
      });
  };

  const clearReason = () => {
    dispatch(change('cancel_investment', 'cancel_detail', ''));
  };

  const isLoading = !offering || !accounts || !investment || !closing;

  return isLoading ? (
    <EMLoadingIcon />
  ) : (
    <Container className="container-narrow">
      <InvestmentTitle title={offering.title} stage={closing.stage} />
      <InvestmentStatus
        step={1}
        investment={investment}
        closing={closing}
        cancelPage
      />
      <Card>
        <h4 className="margin-top-0">Cancel My Investment</h4>
        <form
          id="reason_form"
          name="reason_form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="margin-xx">
            <h6>Reasons for the cancellation:</h6>
            <div className="margin-xx">
              <Field
                type="radio"
                name="cancel_reason"
                id="reason_1"
                value={callIrLabel}
                component={ReduxFormRadioField}
                onChange={() => clearReason()}
                label={callIrLabel}
                hideError
              />
              <Field
                type="radio"
                name="cancel_reason"
                id="reason_2"
                value="I don't have the capital to fund this investment on time."
                component={ReduxFormRadioField}
                onChange={() => clearReason()}
                label="I don't have the capital to fund this investment on time."
                hideError
              />
              <Field
                type="radio"
                name="cancel_reason"
                id="reason_3"
                value="I don't like this offering."
                component={ReduxFormRadioField}
                onChange={() => clearReason()}
                label="I don't like this offering."
                hideError
              />
              <Field
                type="radio"
                name="cancel_reason"
                id="reason_4"
                value="Other"
                component={ReduxFormRadioField}
                onChange={() => clearReason()}
                label="Other"
              />
            </div>
            {cancelReason && cancelReason !== callIrLabel && (
              <Field
                name="cancel_detail"
                id="cancel_detail"
                component={ReduxFormInputField}
                label={getCancelDetailLabel(cancelReason)}
              />
            )}
          </div>

          {cancelReason === callIrLabel && (
            <p>
              We can help get you answers! Give us a ring and we&apos;d be happy
              to walk you through this offering.
              <br /> Our number is 646-844-9918
            </p>
          )}

          <div className="forwardBackButtonWrap">
            {cancelReason === callIrLabel ? (
              <>
                <Button
                  href="tel:+16468449918"
                  style={{
                    width: 180
                  }}
                >
                  Call IR
                </Button>
              </>
            ) : (
              <Button type="submit" loading={sending}>
                Cancel Investment
              </Button>
            )}
            <button
              type="button"
              className="text-link"
              onClick={() => navigate(-1)}
            >
              Back
            </button>
          </div>
        </form>
      </Card>
    </Container>
  );
};

const selector = formValueSelector('cancel_investment');

function mapStateToProps(state) {
  const cancelReason = selector(state, 'cancel_reason');

  return {
    accounts: state.investmentAccount.account,
    investment: state.investments.investment,
    offering: state.offerings.offering,
    closing: state.offerings.closing,
    sending: state.investments.sending,
    cancelReason
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<object, CustomProps>({
    form: 'cancel_investment',
    enableReinitialize: true,
    touchOnBlur: false,
    validate: validateSchema(cancelInvestmentSchema),
    onSubmitFail: scrollToError
  })(CancelInvestment)
);
