import { Button, ErrorLabel } from '@equitymultiple/react-eui';
import AccountProgressContainer from 'components/AccountProgressContainer/AccountProgressContainer';
import { ReduxFormSelectField } from 'components/ReduxFormFields';
import Back from 'images/icons/arrow-back.svg';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loadAuth } from 'redux/actions/auth';
import { updateUserProfile } from 'redux/actions/user-settings';
import { Field, Form, reduxForm } from 'redux-form';
import { scrollToError, throwSubmissionErrors } from 'utilities/validation';
import { validateSchema } from 'utilities/yupValidations';

import { objectivesOptions, reasonOptions } from '../content';
import { getUserAttributes, profileFormValues } from '../helpers';
import { SharedProfileValues } from '../types';
import { profileSchema } from '../validation';

const Objectives = ({
  dispatch,
  error,
  handleSubmit,
  submitting
}: SharedProfileValues) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Get Ready to Invest | EquityMultiple';
  }, []);

  const onSubmit = (values: object) => {
    const submitValues = profileFormValues(values, [
      'investor_profile_attributes.investment_objectives',
      'investor_profile_attributes.investment_reason'
    ]);

    return dispatch(updateUserProfile(submitValues))
      .then(() => {
        dispatch(loadAuth()).then(() => {
          navigate('/users/profile/tolerance');
        });
      })
      .catch(err => {
        throwSubmissionErrors(err);
      });
  };

  return (
    <AccountProgressContainer signupStage="profile" showSkipLink>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h3 className="margin-x">Investment Objectives</h3>
        <p className="margin-xx">
          Let us know your investment preferences to help us personalize your
          financial journey. Your choices guide our recommendations and ensure
          we align our services with your goals and risk tolerance.
        </p>
        <p>My primary real-estate investing objective is:</p>
        <Field
          id="investment_objectives"
          component={ReduxFormSelectField}
          name="investor_profile_attributes.investment_objectives"
          options={objectivesOptions}
          placeholder="Select"
        />
        <p>What are you investing for?</p>
        <Field
          id="investment_reason"
          component={ReduxFormSelectField}
          name="investor_profile_attributes.investment_reason"
          placeholder="Select"
          options={reasonOptions}
          multi
          showMultiLabelsBelow
        />

        {error && <ErrorLabel message={error} className="margin-xxx" />}

        <div className="forwardBackButtonWrapCompact">
          <Button
            type="submit"
            loading={submitting}
            variant="orange"
            className="button-fixed-width margin-top-xx"
          >
            Continue
          </Button>
          <Button
            disabled={submitting}
            variant="outlined"
            data-testid="backLink"
            className="button-fixed-width margin-top-xx arrowBackButton"
            wrapper={<Link to="/users/profile/experience" />}
          >
            <Back />
          </Button>
        </div>
      </Form>
    </AccountProgressContainer>
  );
};

function mapStateToProps(store) {
  return {
    initialValues: getUserAttributes(store.auth.user),
    submitting: store.userSettings.updatingUserProfile
  };
}

export default connect(mapStateToProps)(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  reduxForm<FormValues, Props>({
    form: 'objectives',
    enableReinitialize: true,
    onSubmitFail: scrollToError,
    validate: validateSchema(profileSchema)
  })(Objectives)
);
